import { SaveCurrentAudioPlaying } from "../dto/SaveCurrentAudioPlaying.dto";
import HttpService from "./HttpService";

export const getBooks = () => {
  return HttpService.get(`api/book`);
};

export const getBookDetail = (id: string | number) => {
  return HttpService.get(`api/book/${id}`);
};

export const getBookDetailPlaying = (id: string | number) => {
  return HttpService.get(`api/book/playing/${id}`);
};

export const saveBookPlaying = async (save: SaveCurrentAudioPlaying) => {
  return await HttpService.post("api/book/playing", save);
};
