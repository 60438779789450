import { Typography } from "antd";
import { useEffect, useState } from "react";
import { AudioPlayer, AudioPlayerControlSprite } from "react-audio-player-pro";
import "react-audio-player-pro/dist/style.css";
import { useParams } from "react-router-dom";
import { SaveCurrentAudioPlaying } from "../dto/SaveCurrentAudioPlaying.dto";
import {
  getBookDetail,
  getBookDetailPlaying,
  saveBookPlaying,
} from "../services/HomeService";
const { Title } = Typography;

const DEFAULT_STATE = {
  isMuted: false,
  activeIndex: -1,
  isShuffleOn: false,
  isTrackListOpen: true,
  repeatingState: "none",
} as any;

function BookDetail() {
  const { id } = useParams();
  const [book, setBook] = useState({}) as any;
  const [tracks, setTracks] = useState([]) as any;
  const [defaultState, setDefaultState] = useState(DEFAULT_STATE);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [playingDetail, setPlayingDetail] = useState({}) as any;

  const getCurrentPlaying = async () => {
    const res = await getBookDetailPlaying(id as any);
    let activeIndex = 0;
    if (res && res?.data) {
      const detail = res?.data;
      setPlayingDetail(detail);
      activeIndex = detail?.currentIndex >= 0 ? detail?.currentIndex : 0;
    }
    setDefaultState({
      ...DEFAULT_STATE,
      activeIndex: activeIndex,
    });
  };

  const getDetail = async () => {
    const res = (await getBookDetail(id as any)) as any;
    if (res) {
      setBook(res?.data);
      if (res?.data?.bookDetails) {
        const details = res?.data?.bookDetails as any;
        let trackList = details?.map((item: any) => {
          return {
            src: item?.mp3Path,
          };
        });
        setTracks(trackList);
      }
      await getCurrentPlaying();
    }
  };

  const executeTask = async () => {
    if (currentAudio) {
      const audio = currentAudio as HTMLAudioElement;
      if (audio.paused) {
        return;
      }
      const currentTime = audio.currentTime;
      const trackIndex = tracks.findIndex(
        (item: any) => item.src == audio.currentSrc
      );
      const current: SaveCurrentAudioPlaying = {
        bookId: id,
        currentFile: audio.currentSrc,
        currentIndex: trackIndex,
        currentPosition: Math.round(currentTime),
      };
      await saveBookPlaying(current);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      executeTask();
    }, 15000);

    return () => clearInterval(interval);
  }, [currentAudio]);

  useEffect(() => {
    getDetail();
  }, []);

  const handleOnDidMount = async (audio: HTMLAudioElement | null) => {
    if (audio) {
      audio.currentTime = playingDetail?.currentPosition;
      setCurrentAudio(audio as any);
    }
  };

  return (
    <div className="book-detail" style={{ margin: "20px" }}>
      <Title level={4} className="text-center" style={{ margin: "5px" }}>
        {book?.name}
      </Title>
      <Title level={5} className="text-center" style={{ margin: "5px" }}>
        Tập: {book?.part}
      </Title>
      <Title level={5} className="text-center" style={{ margin: "5px" }}>
        Tác giả: {book?.author}
      </Title>
      <div
        className="player-container"
        style={{ border: "1px solid #dedede", margin: "10px 0 0" }}
      >
        {defaultState.activeIndex >= 0 && (
          <>
            <AudioPlayerControlSprite />
            <AudioPlayer
              trackList={tracks}
              className="audioPlayer"
              defaultState={defaultState}
              onDidMount={handleOnDidMount}
            />
          </>
        )}
      </div>
    </div>
  );
}
export default BookDetail;
